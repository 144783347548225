import React from "react"
import { Section, SectionShadowText, SectionTitle } from "../../components/Section"
import ProductCardList from "../../components/ProductCardList"

const SubCategoryProductSection = (props) => {
	const {sectionShadow, sectionTitle, data, category, buildingType, openQuoteModal } = props;
	return(
		<Section pt="90px" pb="30px" xpt="60px" xpb="0" bgColor="#F7F7F7">
			<div className="container">
				<SectionShadowText>{sectionShadow}</SectionShadowText>      
				<SectionTitle mb="25px">{sectionTitle}</SectionTitle>      
			</div>
			<div className="container">
			    <ProductCardList
                  data={data}
                  category={category}
                  buildingType={buildingType}
                  buildingApp="All"
                  price="All" 
                  roofStyle="All"
				  openQuoteModal={openQuoteModal}
                />   
			</div>
		</Section>
	)
}

export default SubCategoryProductSection